import { Entity } from './Entity';
import { Coordinates } from './Coordinates.entity';
import { MessengerLink } from './MessengerLink.entity';
import { Media } from './Media.entity';
import { Type } from 'class-transformer';
import 'reflect-metadata';
import {CityList} from '../types/city.type';


export class Places extends Entity {
  id!: number;
  number!: number;
  title: string = '';
  description: string | null = null;
  addressName: string | null = null;
  addressLink: string | null = null;

  getTitle() {
    const number = this.number ? this.number + '. ':'';
    return number + this.title;
  }

  @Type(() => Coordinates)
  coordinatesMap!: Coordinates;

  @Type(() => Coordinates)
  coordinatesReal!: Coordinates;
  workingHours: string | null = null;
  price: string | null = null;
  rating: string | null = null;

  @Type(() => MessengerLink)
  messengerLinks: MessengerLink[] = [];
  time: string | null = null;
  phones: string[] = [];
  links: string[] = [];
  category: number[] = [];

  @Type(() => Media)
  media: Media[] = [];
  taxi: string | null = null;

  underTitleLines: {icon: string | null; text: string}[] = [];
  city: CityList = CityList.Moscow
}
