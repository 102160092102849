<div class="menu" [class.active-list]="activeIdx === 1">
  <button
    [class.active]="store.selectedMenuId() === 0"
    (click)="change(0)"
    [attr.data-text]="'MENU.MAP' | translate">
    <tui-svg src="tuiIconMapPinLarge"></tui-svg>
  </button>
  <button
    [class.active]="store.selectedMenuId() === 1"
    (click)="change(1)"
    [attr.data-text]="'MENU.LIST' | translate">
    <tui-svg src="tuiIconListLarge"></tui-svg>
  </button>
  <button
    [class.active]="store.selectedMenuId() === 2"
    (click)="change(2)"
    [attr.data-text]="'MENU.CITY' | translate">
    <tui-icon [icon]="store.currentCity.icon"></tui-icon>
  </button>
</div>
