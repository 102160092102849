<tui-scrollbar>
  <div class="list">
    <div class="list-logo">
      <img [src]="store.currentCity.logo">
    </div>
    <div class="list-wrap">
      @for (item of store.categories()[0]; track item.title) {
        <div class="list-block"
             [ngStyle]="{
                'background-image': imageBg(item),
                'background-color': item.backgroundColor,
             }"
             (click)="openItem($event, item)"
        >
          <span>{{ item.excerpt }}</span>
        </div>
      }
    </div>

    <div class="list-common">
      @for (item of store.categories()[1]; track item.title) {
        <div class="list-common_item">
          <div class="list-common_item-block"
               [style.background-image]="imageBg(item)"
               (click)="openItem($event, item)"
          ></div>
          <div class="list-common_item-text">{{item.excerpt}}</div>
        </div>
      }
    </div>
  </div>
</tui-scrollbar>


@if (isOpen) {
  <app-list-item [DOMRect]="domRect!" [item]="selectedItem!" (onClose)="closeItem()"></app-list-item>
}
