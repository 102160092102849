<tui-root>
  <router-outlet></router-outlet>

  <!-- Управление отображением карты с анимацией -->
  <app-map [@inOutMapAnimation]="store.selectedMenuId() === 0 ? 'open' : 'close'"></app-map>

  <!-- Условное отображение списка с анимацией -->
  <ng-container *ngIf="store.selectedMenuId() === 1">
    <app-list [@inListAnimation] [@outListAnimation]></app-list>
  </ng-container>

  <ng-container *ngIf="store.selectedMenuId() === 2">
    <app-city-list [@inListAnimation] [@outListAnimation]></app-city-list>
  </ng-container>

  <!-- Модальное окно -->
  <app-modal></app-modal>

  <!-- Меню с обработчиком события смены активного элемента -->
  <app-menu></app-menu>

  <!-- Условное отображение приветственного модального окна с анимацией -->
  <ng-container *ngIf="isShowWelcome() && store.configurations().startPopup?.text && store.configurations().startPopup?.buttonText">
    <app-welcome-modal [@outListAnimation] (onClose)="isShowWelcome.set(false)"></app-welcome-modal>
  </ng-container>

  <!-- Условное отображение загрузчика страницы с анимацией -->
  <ng-container *ngIf="isLoading">
    <app-page-loader [@outListAnimation]></app-page-loader>
  </ng-container>
</tui-root>
