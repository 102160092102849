import { Platform } from '@angular/cdk/platform';
import { NgClass, NgOptimizedImage } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  OnDestroy, OnInit,
  Renderer2, signal,
  ViewChild
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from "@capacitor/geolocation";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TuiDestroyService } from "@taiga-ui/cdk";
import { TuiLinkModule, TuiScrollbarModule, TuiSvgModule } from "@taiga-ui/core";
import { TuiBadgeModule, TuiElasticContainerModule, TuiLineClampModule, TuiTabsModule } from "@taiga-ui/kit";
import { takeUntil } from "rxjs";
import { environment } from '../../../environments/environment';
import { RippleDirective } from '../../directives/ripple.directive';
import { Places } from '../../entities/Places.entity';
import { Post } from '../../entities/Posts.entity';
import { UrlPipe } from '../../pipes/url.pipe';
import { AnalyticsService } from "../../services/analytics.service";
import { ModalService } from "../../services/modal.service";
import {Fancybox} from '@fancyapps/ui';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    TuiSvgModule,
    TuiTabsModule,
    TuiBadgeModule,
    NgOptimizedImage,
    TuiLineClampModule,
    TuiElasticContainerModule,
    TuiLinkModule,
    TuiScrollbarModule,
    UrlPipe,
    NgClass,
    RippleDirective,
    TranslateModule
  ],
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  providers: [TuiDestroyService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modal') modalRef!: ElementRef;

  modalService = inject(ModalService);
  destroy$ = inject(TuiDestroyService);
  renderer = inject(Renderer2);
  hostEl = inject(ElementRef);
  cdr = inject(ChangeDetectorRef);
  analytics = inject(AnalyticsService);
  platform = inject(Platform);
  translateService = inject(TranslateService);

  modalData?: Places | Post;
  isActive = false;
  isActiveModal = false;
  isCard = false;
  activeTab = 0;
  showMoreDesc = false;
  domScript?: HTMLScriptElement;
  taxiPriceText = signal(0);
  isLoadImg = false;
  coords?: GeolocationCoordinates;
  watchId: string | null = null;
  permissionGranted = false;

  ngOnInit() {
    if (Capacitor.getPlatform() === 'web') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(({coords}) => {
          this.coords = coords;
          // console.log('this.coords', this.coords);
        }, undefined, {
          enableHighAccuracy: true,
          maximumAge: 0
        });
      }
    } else {
      this.checkPermissionAndWatchPosition();
      setInterval(() => this.checkPermissionAndWatchPosition(), 10000); // Проверка каждые 10 секунд
    }
  }

  ngAfterViewInit() {
    Fancybox.bind(this.hostEl.nativeElement, '[data-fancybox]');

    this.modalService.modal$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(modal => {
        if (this.domScript) {
          this.domScript.remove();
        }

        if (modal) {


          if (this.modalEl) {
            const bottom = window.innerHeight - modal.domRect.bottom;
            this.modalEl.style.left = `${modal.domRect.left}px`;
            this.modalEl.style.bottom = `${bottom}px`;
            this.modalEl.style.width = `${modal.domRect.width}px`;
            this.modalEl.style.height = `${modal.domRect.height}px`;
            this.modalEl.style.maxHeight = `${modal.domRect.height}px`;
          }

          this.modalData = modal!.data;
          this.getTaxiPrice()
          this.activeTab = this.modalData.description && (this.modalData.phones?.length || this.modalData.links?.length)
            ? 0
            : !!this.modalData.description?.trim().length
              ? 0
              : 1

          // Отслеживаем открытие модального окна
          if (this.modalData) {
            this.analytics.trackOpenPlaceModal(
              this.valueToAnalytics() ? this.valueToAnalytics().value : '',
              this.valueToAnalytics() ?  this.valueToAnalytics().type : ''
            );
          }

          this.isActiveModal = true;
          this.isCard = modal.isCard;
          this.cdr.detectChanges();
          setTimeout(() => {
            this.isActive = true;
            this.renderer.addClass(this.hostEl.nativeElement, 'active')
            this.cdr.detectChanges();
          }, 50)

        } else {
          setTimeout(() => {
            this.isActive = false;
            this.renderer.removeClass(this.hostEl.nativeElement, 'active')
            this.modalEl.style.left = `auto`;
            this.modalEl.style.bottom = `auto`;
            this.modalEl.style.width = `auto`;
            this.modalEl.style.height = `auto`;
            this.modalData = undefined;
            this.isCard = false;
            this.activeTab = 0;
            this.isLoadImg = false;
            this.taxiPriceText.set(0);
            this.cdr.detectChanges();
          }, 100)
        }
      })
  }

  valueToAnalytics() {
    let obj = {
      value: '',
      type: ''
    };
    if (this.modalData instanceof Places) {
      console.log('this.modalData instanceof Places');
      obj = {
        value: `${this.modalData.number} - ${this.modalData!.title}`,
        type: 'open_point'
      }
    }
    if (this.modalData instanceof Post) {
      console.log('this.modalData instanceof Post')
      obj = {
        value: `${this.modalData.id} - ${this.modalData!.title}`,
        type: 'open_post'
      }
    }
    return obj;
  }

  async ngOnDestroy() {
    Fancybox.unbind(this.hostEl.nativeElement);
    Fancybox.close();
    if (this.domScript) {
      this.domScript.remove();
    }
    if (this.watchId) {
      Geolocation.clearWatch({ id: this.watchId });
    }
  }

  getTaxiPrice() {
    if (this.asPost() || !(this.asPlaces()?.coordinatesReal?.lat && this.asPlaces()?.coordinatesReal?.lng)) {
      return;
    }

    let data = new URLSearchParams({
      'clid': environment.clid,
      'apikey': environment.apikey,
      'rll': this.coords
        ? `${this.coords.longitude},${this.coords.latitude}~${this.asPlaces()?.coordinatesReal?.toStringData()}`
        : this.asPlaces()?.coordinatesReal?.toStringData() ?? '',
      'class': 'business',
      'lang': 'en'
    }).toString();

    fetch(`https://taxi-routeinfo.taxi.yandex.net/taxi_info?${data}`)
      .then(res => res.json())
      .catch(err => console.log(err))
      .then(val => {
        if (val) {
          this.taxiPriceText.set(val.options[0]?.price ?? 0);
        }
      })
  }


  get taxiLink(): string {
    if (this.asPost() || !(this.asPlaces()?.coordinatesReal?.lat && this.asPlaces()?.coordinatesReal?.lng)) {
      return '';
    }
    const coords = this.asPlaces()?.coordinatesReal ?? {
      lat: '0',
      lng: '0'
    }
    const data = new URLSearchParams({
      'end-lat': coords.lat.toString(),
      'end-lon': coords.lng.toString(),
      'tariffClass': 'business',
      'ref': 'wow-atlas',
      'appmetrica_tracking_id': '1178268795219780156',
      'lang': 'en',
    })

    if (this.coords) {
      data.set('start-lat', this.coords.latitude.toString());
      data.set('start-lon', this.coords.longitude.toString());
    }

    return `https://3.redirect.appmetrica.yandex.com/route?${data.toString()}`
  }

  loadScript() {
    this.domScript = document.createElement('script');
    this.domScript.innerHTML = '';
    this.domScript.src = '//yastatic.net/taxi-widget/ya-taxi-widget.js';
    document.body.appendChild(this.domScript);
  }

  get modalEl(): HTMLElement {
    return this.modalRef.nativeElement;
  }

  onCloseModal() {
    this.isActiveModal = false;
    this.showMoreDesc = false;
    this.modalService.close();
    this.cdr.detectChanges();
  }

  activeTabChange(id: number) {
    this.activeTab = id;
    this.cdr.detectChanges();
  }


  sendTrack(type: any, link?: string) {
    if (this.modalData) {
      const value = `${this.modalData.id} - ${this.modalData!.title}`;
      this.analytics.trackModalElementClick(
        type,
        value,
        link ? '' : ''
      )
    }
  }

  asPlaces() {
    return this.modalData instanceof Places ? this.modalData as Places : undefined;
  }

  asPost() {
    return this.modalData instanceof Post ? this.modalData as Post : undefined;
  }

  price(num: number) {
    return new Array(num).fill('$').join('')
  }

  onLoad() {
    if (this.isLoadImg) {
      return;
    }
    this.isLoadImg = true;
    this.cdr.detectChanges();
  }

  async checkPermissionAndWatchPosition() {
    const permission = await Geolocation.requestPermissions();
    this.permissionGranted = permission.location === 'granted';

    if (!this.permissionGranted) {
      console.warn('Permission not granted for geolocation');
      return;
    }

    // Если разрешение получено, отслеживаем изменения геопозиции
    if (this.permissionGranted && !this.watchId) {
      this.watchPosition();
    }
  }

  async watchPosition() {
    this.watchId = await Geolocation.watchPosition({enableHighAccuracy: true}, (position, err) => {
      if (err) {
        console.error('Error watching position:', err);
        return;
      }
      if (position) {
        this.coords = {
          latitude: position.coords.latitude ?? null,
          longitude: position.coords.longitude ?? null,
          accuracy: position.coords.accuracy,
          altitude: position.coords.altitude ?? null,
          altitudeAccuracy: position.coords.altitudeAccuracy ?? null,
          heading: position.coords.heading ?? null,
          speed: position.coords.speed ?? null
        };
      }
    });
  }
}
