import {computed, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {plainToInstance} from 'class-transformer';
import {environment} from '../../environments/environment';
import {Places} from '../entities/Places.entity';
import {Post} from '../entities/Posts.entity';
import {Taxonomy} from '../entities/Taxonomy.entity';
import {Configuration} from '../entities/Configuration.entity';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, map} from 'rxjs';
import {Response} from '../entities/Response';
import {CityCard, CityList} from '../types/city.type';

const SELECTED_CITY = 'selectedCity';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  http = inject(HttpClient);

  private _places: WritableSignal<Places[]> = signal([])
  places = computed(this._places);

  private _posts: WritableSignal<Post[]> = signal([]);
  posts = computed(this._posts);

  private _categories: WritableSignal<[Taxonomy[], Taxonomy[]]> = signal([[], []]);
  categories = computed(this._categories);

  private _configurations: WritableSignal<Configuration> = signal({} as Configuration);
  configurations = computed(this._configurations);

  isLoaded = new BehaviorSubject<boolean>(false);

  cityList = new Map<CityList, CityCard>([
    [CityList.Moscow, {
      icon: '/assets/images/icons/moscow.svg',
      logo: '/assets/images/logo.svg',
      map: '/assets/images/map-preview/moscow.jpg'
    }],
    [CityList.Spb, {
      icon: '/assets/images/icons/spb.svg',
      logo: '/assets/images/spb-logo.svg',
      map: '/assets/images/map-preview/spb.jpg'
    }],
  ]);

  selectedCity = signal<CityList>(localStorage.getItem(SELECTED_CITY) as CityList || CityList.Moscow);

  private _selectedMenuId = signal(0);
  selectedMenuId = computed(this._selectedMenuId);

  constructor() { }

  loadData() {
    return this.http.get<Response>(environment.apiUrl+'/content')
      .pipe(
        map((response: Response) => {
          if (!response.status) {
            return false
          }

          this._places.set(
            plainToInstance(Places, response.result['places'])
          );

          // this._places.set(this.places().filter(item => item.number != 0))

          this._posts.set(
            plainToInstance(Post, response.result['posts'])
          );

          const categories = plainToInstance(Taxonomy, response.result['categories']);

          if (categories.length) {
            categories.forEach(category => {
              if (category.cover?.getFull()?.urlx3) {
                this.preloadImg(category.cover.getFull()?.urlx3!)
              }
            })
          }

          this._categories.set([
            [...categories.slice(0, 11)],
            [...categories.slice(11)]
          ]);

          this._configurations.set(
            plainToInstance(Configuration, response.result['configuration']) as unknown as Configuration
          );

          this.isLoaded.next(true);

          for (const [key, value] of this.cityList) {
            this.preloadImg(value.logo)
          }

          return true
        })
      );
  };

  preloadImg(url: string) {
    const img = new Image();
    img.src = url;
  }

  changeCity(city: CityList) {
    if (city === this.selectedCity()) {
      this.changeMenuId(0);
      return
    }
    this.selectedCity.set(city);
    localStorage.setItem(SELECTED_CITY, city);
  }

  get currentCity(): CityCard {
    return this.cityList.get(this.selectedCity())!;
  }

  changeMenuId(id: number) {
    this._selectedMenuId.set(id);
  }
}
