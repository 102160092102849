
<!--     [leafletBaseLayers]="baseLayer"-->
<div class="map"
     leaflet
     [leafletOptions]="option"
     [leafletLayers]="markers"
     (leafletClick)="onClick($event)"
>
</div>

