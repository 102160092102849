export type CityCard = {
  icon: string;
  map: string;
  logo: string;
}

export enum CityList {
  Moscow = 'moscow',
  Spb = 'spb'
}

export const CityName = {
  [CityList.Moscow]: 'Moscow',
  [CityList.Spb]: 'Saint Petersburg',
}
