import {Component, inject} from '@angular/core';
import {TuiScrollbarModule, TuiSvgModule} from '@taiga-ui/core';
import {StoreService} from '../../services/store.service';
import {KeyValuePipe} from '@angular/common';
import {TuiIconModule} from '@taiga-ui/experimental';
import {CityList, CityName} from '../../types/city.type';

@Component({
  selector: 'app-city-list',
  standalone: true,
  imports: [
    TuiScrollbarModule,
    KeyValuePipe,
    TuiIconModule,
    TuiSvgModule
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class CityListComponent {
  store = inject(StoreService);

  cityNames = CityName;
}
