<tui-scrollbar>
  <div class="list">
    @for (city of store.cityList | keyvalue; track city.key) {
      <div class="list-card" (click)="store.changeCity(city.key)">
        <div class="list-card_img">
          <img [src]="city.value.map">
        </div>
        <div class="list-card_wrap">
          <div class="list-card_city">
            <div class="icon">
              <tui-icon [icon]="city.value.icon" />
            </div>
            <div class="text">{{cityNames[city.key]}}</div>
          </div>

          @if (city.key === store.selectedCity()) {
            <div class="list-card_selected">
              <tui-svg src="/assets/images/icons/selected-city.svg" />
            </div>
          }
        </div>
      </div>
    }
  </div>
</tui-scrollbar>
