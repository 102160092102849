import { AsyncPipe } from "@angular/common";
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiDestroyService } from "@taiga-ui/cdk";
import { TuiSvgModule } from "@taiga-ui/core";
import { RippleDirective } from '../../directives/ripple.directive';
import { ModalService } from "../../services/modal.service";
import {StoreService} from '../../services/store.service';
import {TuiIconModule} from '@taiga-ui/experimental';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [TuiSvgModule, AsyncPipe, RippleDirective, TranslateModule, TuiIconModule],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  providers: [TuiDestroyService]
})
export class MenuComponent implements OnInit {

  modalService = inject(ModalService);
  store = inject(StoreService);

  isOpen = false;

  activeIdx = 0;

  ngOnInit() {
  }

  change(id: number) {
    if (id === this.store.selectedMenuId()) {
      return;
    }
    this.store.changeMenuId(id);
    this.modalService.close();
  }
}
