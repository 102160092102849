import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { version } from '../../../package.json';
import { environment } from '../../environments/environment';

export const TokenInterceptor: HttpInterceptorFn = (req, next) => {
  const platform = inject(Platform);

  const os = platform.isBrowser
    ? 'browser'
    : platform.ANDROID
      ? 'android'
      : platform.IOS ? 'ios' : 'browser';

  // Определяем язык устройства
  const deviceLanguage = navigator.language || navigator.languages[0];
  const language = deviceLanguage.startsWith('ru') ? 'ru' : 'en';

  console.log({ platform, os, language });

  const modifiedRequest = req.clone({
    setHeaders: {
      'Accept-Language': language,
      'Device-Language': deviceLanguage,
      'Authorization': environment.apiUserToken,
      'Version': `${os}_${version}`,
    },
  });

  return next(modifiedRequest);
};
