<div class="wm">
  <div class="wm-overlay" (click)="onClose.emit()"></div>
  <div class="wm-modal">
    <div class="wm-modal-logo">
      <img src="/assets/icons/welcome-logo.svg">
    </div>
    <div class="wm-modal-text" [innerHTML]="welcomeText"></div>
    <a class='wm-modal-btn'
       appRipple
       target='_blank'
       (click)='onClose.emit()'
    >{{ store.configurations().startPopup?.buttonText ?? 'Close' }}</a>
  </div>
</div>
