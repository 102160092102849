import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode} from '@angular/core';
import { provideAnimations } from "@angular/platform-browser/animations";
import {provideRouter, Router} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TuiRootModule } from "@taiga-ui/core";
import { routes } from './app.routes';
import { TokenInterceptor } from './interceptors/token-interceptor';
import * as Sentry from "@sentry/angular";
import { environment } from '../environments/environment';

// Функция для создания TranslateHttpLoader, которая указывает путь к JSON-файлам с переводами
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.apiUrl}/translation/`, '');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    importProvidersFrom(
      TuiRootModule,
      LeafletModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    provideHttpClient(
      withInterceptors(
        [TokenInterceptor]
      )
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
