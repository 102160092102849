export interface IMapLabel {
  img: string;
  latLng: [number, number];
}

export const MOSCOW_MAP_LABELS: IMapLabel[] = [
  {
    img: '/assets/images/labels/solnechegorsk.webp',
    latLng: [-95, 84]
  },
  {
    img: '/assets/images/labels/dmitrov.webp',
    latLng: [-17, 245]
  },
  {
    img: '/assets/images/labels/sergiev-posad.webp',
    latLng: [-50, 345]
  },
  {
    img: '/assets/images/labels/pushkino.webp',
    latLng: [-1628, 270]
  },
  {
    img: '/assets/images/labels/orehovo-zuevo.webp',
    latLng: [-237, 478]
  },
  {
    img: '/assets/images/labels/ramenskoe.webp',
    latLng: [-327, 394]
  },
  {
    img: '/assets/images/labels/voskresensk.webp',
    latLng: [-407, 474]
  },
  // {
  //   img: '/assets/images/labels/kolomna.webp',
  //   latLng: [-492 , 460]
  // },
  // {
  //   img: '/assets/images/labels/mihnevo.webp',
  //   latLng: [-486, 340]
  // },
  // {
  //   img: '/assets/images/labels/chechov.webp',
  //   latLng: [-486, 198]
  // },
  {
    img: '/assets/images/labels/podolsk.webp',
    latLng: [-370, 216]
  },
  {
    img: '/assets/images/labels/naro-fominsk.webp',
    latLng: [-386, 38]
  },
  {
    img: '/assets/images/labels/odincovo.webp',
    latLng: [-287, 144]
  },
  {
    img: '/assets/images/labels/moscow.webp',
    latLng: [-314, 244]
  },
  {
    img: '/assets/images/labels/moscow-region.webp',
    latLng: [-22, 62]
  },
]


export const SPB_MAP_LABELS: IMapLabel[] = [
  {
    img: '/assets/images/spb-labels/gatchina.webp',
    latLng: [-442, 196.75]
  },
  {
    img: '/assets/images/spb-labels/kolpino.webp',
    latLng: [-386, 365]
  },
  {
    img: '/assets/images/spb-labels/kronstadt.webp',
    latLng: [-228, 95.75]
  },
  {
    img: '/assets/images/spb-labels/leningrad-oblast.webp',
    latLng: [-33, 74.25]
  },
  {
    img: '/assets/images/spb-labels/peterhof.webp',
    latLng: [-282.5, 105.5]
  },
  {
    img: '/assets/images/spb-labels/shlisselburg.webp',
    latLng: [-266.25, 483.25]
  },
  {
    img: '/assets/images/spb-labels/spb.webp',
    latLng: [-345.3, 206]
  },
  {
    img: '/assets/images/spb-labels/zelenogorsk.webp',
    latLng: [-132.5, 45]
  },
  {
    img: '/assets/images/spb-labels/toksovo.webp',
    latLng: [-122.25, 413]
  },
]
