<div class="modal-wrapper" [class.active]="isActiveModal">
  <div class="modal-backdrop" (click)="onCloseModal()"></div>

  <div class="modal" [class.active]="isActive" [class.modal-card]="isCard" #modal >
    <div class="modal-start"></div>
    @if (modalData) {
      <div class="modal-block">

        @if (!(asPost() && asPost()?.viewType === 'linkList')) {
          <swiper-container
              slides-per-view="1"
              pagination-enabled="true"
              pagination-dynamic-bullets="true"
          >
            @for (img of modalData.media; track $index) {
              <swiper-slide>
                <a class="modal-img"
                     [ngClass]="{
                        'loaded': isLoadImg
                     }"
                   data-fancybox="gallery"
                   [href]="img?.getFull()?.urlx2!"
                >
                  <img [ngSrc]="img?.getFull()?.urlx2!" fill loading="eager" (load)="onLoad()">
                </a>
              </swiper-slide>
            }
          </swiper-container>

          <tui-scrollbar class="modal-scrollbar">

            @if (asPlaces(); as places) {
              <div class="modal-content">
                <div class="modal-title">{{ places.getTitle() }}</div>

                @if (places.addressName) {
                  <div class="modal-item">
                    <tui-svg src="tuiIconMapPinLarge"></tui-svg>
                    <span>{{ 'MODAL.ADDRESS' | translate: { addressName: places.addressName } }}</span>
                  </div>
                }

                @if (places.workingHours && places.workingHours != '0') {
                  <div class="modal-item modal-item-time">
                    <tui-svg src="tuiIconClockLarge"></tui-svg>
                    <span>{{ 'MODAL.WORKING_HOURS' | translate: { workingHours: places.workingHours } }}</span>
                  </div>
                }

                @for (item of places.underTitleLines; track item.text) {
                  <div class="modal-item">
                    <tui-svg [src]="item.icon"></tui-svg>
                    <span>{{ item.text }}</span>
                  </div>
                }

                <div class="modal-badges">
                  @if (places.rating) {
                    <tui-badge status="custom" [value]="places.rating" class="badge-rating">
                      <tui-svg src="tuiIconStar"></tui-svg>
                    </tui-badge>
                  }

                  @if (places.time) {
                    <tui-badge status="custom" [value]="places.time" class="badge-time">
                      <tui-svg src="tuiIconClock"></tui-svg>
                    </tui-badge>
                  }

                  @if (places.price) {
                    <tui-badge status="custom" [value]="price(+places.price)" class="badge-price"></tui-badge>
                  }
                </div>

                <div class="modal-btns">
                  @for (link of places.messengerLinks; track link.link) {
                    <a [class]="'modal-btn-social '+link.type" [href]="link.link" target="_blank" (click)="sendTrack(link.type, link.link)">
                      @if (link.type === 'whatsapp') {
                        <svg width="16" height="16" appRipple viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1232_862)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1969 2.79823C11.8171 1.42471 9.98374 0.666664 8.03105 0.666664C4.00631 0.666664 0.728753 3.92698 0.728753 7.93346C0.726366 9.2143 1.06295 10.4642 1.70271 11.5669L0.666687 15.3333L4.53865 14.321C5.60332 14.9008 6.80644 15.205 8.02867 15.2074H8.03105C12.0558 15.2074 15.331 11.9471 15.3334 7.93821C15.3334 5.99676 14.5742 4.17174 13.1969 2.79823ZM8.03105 13.9788H8.02867C6.94012 13.9788 5.87068 13.6865 4.93969 13.1376L4.71769 13.0069L2.41886 13.6057L3.03236 11.3768L2.88913 11.1486C2.2804 10.1862 1.96052 9.0741 1.96052 7.93346C1.96052 4.60423 4.68426 1.89522 8.03344 1.89522C9.65432 1.89522 11.1773 2.52495 12.3232 3.66558C13.469 4.8086 14.0992 6.32469 14.0992 7.93821C14.0992 11.2698 11.3755 13.9788 8.03105 13.9788ZM11.3587 9.45431C11.1773 9.36401 10.2798 8.92439 10.1127 8.86498C9.94555 8.80319 9.8238 8.77468 9.70206 8.95528C9.58032 9.13826 9.23179 9.54698 9.12437 9.66818C9.01933 9.78699 8.91191 9.80363 8.73049 9.71333C8.54668 9.62303 7.95944 9.43054 7.26239 8.81032C6.72051 8.33031 6.35289 7.73385 6.24785 7.55325C6.14043 7.37027 6.23591 7.27284 6.32663 7.18254C6.41018 7.10175 6.51044 6.97105 6.60115 6.86411C6.69186 6.75956 6.72289 6.68351 6.78496 6.56232C6.84464 6.44113 6.81361 6.33419 6.76825 6.24389C6.72289 6.15359 6.35766 5.25772 6.20488 4.89414C6.05688 4.54007 5.90649 4.58997 5.79429 4.58284C5.68926 4.57809 5.56751 4.57809 5.44577 4.57809C5.32402 4.57809 5.12589 4.62324 4.95879 4.80622C4.79169 4.98682 4.32142 5.42644 4.32142 6.31994C4.32142 7.21343 4.9755 8.07842 5.06621 8.19961C5.15692 8.31842 6.35289 10.1529 8.18383 10.9395C8.61829 11.1272 8.95727 11.2389 9.22224 11.3221C9.65909 11.4599 10.0577 11.4409 10.3729 11.3958C10.7238 11.3435 11.4518 10.9561 11.6046 10.5308C11.755 10.1078 11.755 9.74422 11.7097 9.66818C11.6643 9.59213 11.5426 9.54698 11.3587 9.45431Z" fill="white"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_1232_862">
                              <rect width="16" height="16" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                      }

                      {{link.text}}
                    </a>
                  }

                  @if (!!places.coordinatesReal.lat || !!places.coordinatesReal.lng) {
                    <a class="modal-btn-social taxi" appRipple [href]="taxiLink" (click)="sendTrack('taxi')">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1249_                        <clip0_1249_280)">
                          <path d="M20 0H0V10H20V0Z" fill="#FFDD33"/>
                          <path d="M10 10H0V20H10V10Z" fill="white"/>
                          <path d="M20 10H10V20H20V10Z" fill="black"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_1249_280">
                            <rect width="20" height="20" rx="5" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>

                      {{ 'MODAL.TAXI' | translate }}
                      @if (taxiPriceText()) {
                        {{ 'MODAL.TAXI_PRICE' | translate: { taxiPriceText: taxiPriceText() } }}
                      }
                    </a>
                  }
                </div>

                <div class="modal-tab">
                  @if (places.description && (places.phones || places.links)) {
                    <tui-tabs [activeItemIndex]="activeTab">
                      <button
                          tuiTab
                          (click)="activeTabChange(0)"
                      >
                        {{ 'MODAL.DESCRIPTION' | translate }}
                      </button>
                      <button
                          tuiTab
                          (click)="activeTabChange(1)"
                      >
                        {{ 'MODAL.CONTACTS' | translate }}
                      </button>
                    </tui-tabs>
                  }

                  <div class="modal-tab-content">
                    @if (activeTab === 0) {
                      <div class="content" [innerHTML]="places.description"></div>
                    }

                    @if (activeTab === 1) {
                      @for (phone of places.phones; track phone) {
                        @if (phone.length) {
                          <div class="modal-tab-item sss" (click)="sendTrack('phone', phone)">
                            <a tuiLink
                               icon="tuiIconPhoneLarge"
                               iconAlign="left"
                               [href]="'tel:' + phone"
                            >{{phone}}</a>
                          </div>
                        }
                      }
                      @for (link of places.links; track link) {
                        @if (link.length) {
                          <div class="modal-tab-item asds" (click)="sendTrack('link', link)">
                            <a tuiLink
                               icon="tuiIconGlobeLarge"
                               iconAlign="left"
                               [href]="'https://' + link"
                               target="_blank"
                            >{{link}}</a>
                          </div>
                        }
                      }
                    }
                  </div>
                </div>
              </div>
            } @else {
              @if (asPost(); as post) {
                <div class="modal-content">
                  <div class="modal-title">{{post.title}}</div>

                  <div class="modal-tab">
                    @if (post.description && (post.phones || post.links)) {
                      <tui-tabs [activeItemIndex]="activeTab">
                        <button
                            tuiTab
                            (click)="activeTabChange(0)"
                        >
                          {{ 'MODAL.DESCRIPTION' | translate }}
                        </button>
                        <button
                            tuiTab
                            (click)="activeTabChange(1)"
                        >
                          {{ 'MODAL.CONTACTS' | translate }}
                        </button>
                      </tui-tabs>
                    }

                    <div class="modal-tab-content">
                      @if (activeTab === 0) {
                        <div class="content" [innerHTML]="post.description"></div>
                      }

                      @if (activeTab === 1) {
                        @for (phone of post.phones; track phone) {
                          <div class="modal-tab-item" (click)="sendTrack('phone', phone)">
                            <a tuiLink
                               icon="tuiIconPhoneLarge"
                               iconAlign="left"
                               [href]="'tel:' + phone"
                            >{{phone}}</a>
                          </div>
                        }
                        @for (link of post.links; track link.url) {
                          @if (link.url) {
                            <div class="modal-tab-item" (click)="sendTrack('contact', link.url)">
                              <a tuiLink
                                 icon="tuiIconGlobeLarge"
                                 iconAlign="left"
                                 [href]="link.url"
                                 target="_blank"
                              >{{link.title}}</a>
                            </div>
                          }
                        }
                      }
                    </div>
                  </div>
                </div>
              }
            }
          </tui-scrollbar>
        } @else {
          <div class="modal-content modal-content-transfers">
            <div class="modal-title">{{asPost()?.title}}</div>

            <div class="modal-content-cards">
              @for (link of asPost()?.links; track link.url) {
                <a [href]="link.url" target="_blank" appRipple class="modal-card-link"><span>{{link.title}}</span></a>
              }
            </div>
          </div>
        }
      </div>
    }
  </div>

  <div class="modal-menu">
    <button class="modal-menu-close" appRipple (click)="onCloseModal()">
      <tui-svg src="tuiIconArrowLeftLarge"></tui-svg>
    </button>
  </div>
</div>

