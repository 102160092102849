{
  "name": "wow-atlas",
  "version": "1.0.12",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "add platform android": "npx cap add android && node ./update-version.js && node ./update-manifest.js",
    "build android": "npx cap open android",
    "add platform ios": "npx cap add ios && node ./update-ios-version.js && ./update-ios-permissions.js",
    "build ios": "npx cap open ios",
    "generate resources": "npx @capacitor/assets generate && powershell -ExecutionPolicy Bypass -File ./copy-resources.ps1",
    "build run android": "npx cap run android --external -livereload",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "clean_cache": "npm cache clean --force",
    "build_dev": "ng build --configuration=base,development",
    "build_stage": "ng build --configuration=base,stage --output-hashing=all --stats-json",
    "build_prod": "ng build --configuration=base,production --output-hashing=all --stats-json && npm run sentry:sourcemaps",
    "sentry_update_map": "npx @sentry/wizard@latest -i sourcemaps",
    "update-android-version": "node ./update-version.js",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org lobster --project wow-atlas dist/browser && sentry-cli --url https://sentry.lobsterlab.io/ sourcemaps upload --org lobster --project wow-atlas dist/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.0.0",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@angular/service-worker": "^17.0.0",
    "@asymmetrik/ngx-leaflet": "^17.0.0",
    "@capacitor-community/firebase-analytics": "^6.0.0",
    "@capacitor/android": "^6.1.0",
    "@capacitor/app": "^6.0.1",
    "@capacitor/assets": "^3.0.5",
    "@capacitor/core": "^6.1.2",
    "@capacitor/geolocation": "^6.0.1",
    "@capacitor/ios": "^6.1.0",
    "@capacitor/preferences": "^6.0.1",
    "@capacitor/splash-screen": "^6.0.2",
    "@fancyapps/ui": "^5.0.36",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^8.41.0",
    "@sentry/cli": "^2.39.1",
    "@taiga-ui/addon-mobile": "^3.79.0",
    "@taiga-ui/cdk": "^3.79.0",
    "@taiga-ui/core": "^3.79.0",
    "@taiga-ui/experimental": "^3.81.0",
    "@taiga-ui/icons": "^3.79.0",
    "@taiga-ui/kit": "^3.79.0",
    "@taiga-ui/styles": "^3.79.0",
    "@tinkoff/ng-dompurify": "4.0.0",
    "class-transformer": "^0.5.1",
    "dompurify": "3.0.6",
    "firebase": "^10.12.5",
    "leaflet": "^1.9.4",
    "plist": "^3.1.0",
    "reflect-metadata": "^0.2.2",
    "rxjs": "~7.8.0",
    "swiper": "^11.1.4",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.5",
    "@angular/cli": "^17.0.5",
    "@angular/compiler-cli": "^17.0.0",
    "@angular/localize": "^17.3.12",
    "@capacitor/cli": "^6.1.2",
    "@types/dompurify": "3.0.5",
    "@types/jasmine": "~5.1.0",
    "@types/leaflet": "^1.9.12",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
